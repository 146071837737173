import React, { useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Close from "@material-ui/icons/Close";

import BaseMoneyInput from "../../components/Inputs/BaseMoneyInput";
import OptionsGroup from "../../components/OptionsGroup";
import {
  addOptions,
  moneyTypeOptions,
} from "../../../../commons/loan-application-constants";
import { getLabel } from "../../../../utils/loan-application.utils";
import Button from "../../components/Button";
import { useStyles as useSectionStyles } from "../../../../utils/loan-application.styles";

const BaseSalaryStep = ({
  formik,
  index,
  handlePrevStep,
  handleNextStep,
  isEditing,
  handleDeleteEmployment,
}) => {
  const sectionClasses = useSectionStyles();
  const formikTouched =
    formik.touched?.income?.items?.at?.(index).incomeItems || [];
  const formikErrors =
    formik.errors?.income?.items?.at?.(index).incomeItems || [];

  const handleAddIncome = (value) => {
    const addedOption = addOptions.find((option) => option.value === value);
    const currentAdditionalIncome =
      formik.values.income.items[index].incomeItems;
    formik.setFieldValue(`income.items[${index}].incomeItems`, [
      ...currentAdditionalIncome,
      {
        category: addedOption.value,
        frequency: moneyTypeOptions[0].value,
      },
    ]);
  };

  const handleRemoveIncome = (incomeIndex) => {
    const updatedIncome = formik.values.income.items[index].incomeItems.filter(
      (_, i) => i !== incomeIndex
    );
    formik.setFieldValue(`income.items[${index}].incomeItems`, updatedIncome);
  };

  const onNextStep = () => {
    formik.values.income.items[index].incomeItems.forEach((_, i) => {
      formik.setFieldTouched(`income.items[${index}].incomeItems[${i}].amount`);
    });

    if (
      formik.errors.income?.items
        ?.at(index)
        .incomeItems.some((incomeError) => incomeError?.amount)
    ) {
      return;
    }

    handleNextStep();
  };

  const getMaxSliderRange = (category) => {
    if (category === "bonus") {
      return 50000;
    }
    return 10000;
  };

  useEffect(() => {
    if (!formik.values.income.items[index].incomeItems[0].category) {
      formik.setFieldValue(
        `income.items[${index}].incomeItems[0].category`,
        "base-salary"
      );
    }
  }, [formik, index]);

  return (
    <Box display="flex" flexDirection="column" gridRowGap={48}>
      <Grid container xs={12} sm={8} md={6} spacing={4}>
        <Grid item xs={12}>
          <BaseMoneyInput
            options={moneyTypeOptions}
            label="Base Salary"
            selectedOption={
              formik.values.income.items[index].incomeItems[0].frequency
            }
            setSelectedOption={(value) =>
              formik.setFieldValue(
                `income.items[${index}].incomeItems[0].frequency`,
                value
              )
            }
            onChange={(value) =>
              formik.setFieldValue(
                `income.items[${index}].incomeItems[0].amount`,
                value
              )
            }
            value={formik.values.income.items[index].incomeItems[0].amount}
            minValue={0}
            maxValue={200000}
            error={
              formikTouched.at(0)?.amount && Boolean(formikErrors.at(0)?.amount)
            }
            helperText={
              formikTouched.at(0)?.amount && formikErrors.at(0)?.amount
            }
          />
        </Grid>
        {formik.values.income.items[index].incomeItems.map(
          (additionalIncome, incomeIndex) =>
            incomeIndex !== 0 && (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item xs={12} key={incomeIndex}>
                <BaseMoneyInput
                  options={moneyTypeOptions}
                  selectedOption={additionalIncome.frequency}
                  setSelectedOption={(value) =>
                    formik.setFieldValue(
                      `income.items[${index}].incomeItems[${incomeIndex}].frequency`,
                      value
                    )
                  }
                  label={getLabel(additionalIncome.category)}
                  isRemovable
                  handleRemove={() => handleRemoveIncome(incomeIndex)}
                  onChange={(value) =>
                    formik.setFieldValue(
                      `income.items[${index}].incomeItems[${incomeIndex}].amount`,
                      value
                    )
                  }
                  value={additionalIncome.amount}
                  maxValue={getMaxSliderRange(additionalIncome.category)}
                  error={
                    formikTouched.at(incomeIndex)?.amount &&
                    Boolean(formikErrors.at(incomeIndex)?.amount)
                  }
                  helperText={
                    formikTouched.at(incomeIndex)?.amount &&
                    formikErrors.at(incomeIndex)?.amount
                  }
                />
              </Grid>
            )
        )}
        <Grid item>
          <OptionsGroup
            title="Add +"
            options={addOptions}
            onSelect={handleAddIncome}
          />
        </Grid>
      </Grid>
      {isEditing && (
        <Grid container>
          <Button
            endIcon={<Close />}
            onClick={() => handleDeleteEmployment(index)}
            customColor="danger"
          >
            Delete this Employment
          </Button>
        </Grid>
      )}
      <Grid
        container
        xs={12}
        sm={8}
        md={6}
        justifyContent="space-between"
        classes={{ root: sectionClasses.navigationButtonsContainer }}
      >
        <Button startIcon={<NavigateBeforeIcon />} onClick={handlePrevStep}>
          Previous
        </Button>
        <Button endIcon={<NavigateNextIcon />} onClick={onNextStep}>
          Next
        </Button>
      </Grid>
    </Box>
  );
};

export default BaseSalaryStep;
